canvas {
  position: relative;
  height: 100vh;
  background-color: black
}

@media(max-width: 800px) {
  canvas {
    height: 90vh;
  }
}
