.technologies__titule {
  text-transform: uppercase;
  font-size: 3.5rem;
}

.technologies__buttons {
  position: relative;
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
