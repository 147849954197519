.button {
  font-size: 3rem;
  background-color: white;
  color: black;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 0 auto;
  margin-bottom: 2rem;
  margin-bottom: 60rem;
  width: 30rem;
  box-shadow: 0px 0px 15px 5px red;
  border-radius: 1rem;
}

.button__text {
  padding: 0;
  margin: 0;
}

.front-end {
  box-shadow: 0px 0px 15px 5px lightgreen;
}

.back-end {
  box-shadow: 0px 0px 15px 5px purple;
}

.design-patterns {
  box-shadow: 0px 0px 15px 5px red;
}

.agile-methods {
  box-shadow: 0px 0px 15px 5px blue;
}
