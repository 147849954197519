html {
  font-size: 10px;
}

html, body {
  max-width: 100vw;
  overflow-x: hidden;
  animation-name: no-scroll;
  animation-duration: 7s;
  animation-fill-mode: both;
}

@media(max-width: 900px) {
  html {
    font-size: 9px;
  }
}

@media(max-width: 800px) {
  html {
    font-size: 8px;
  }
}

@media(max-width: 600px) {
  html {
    font-size: 7px;
  }
}

@media(max-width: 500px) {
  html {
    font-size: 6px;
  }
}

@media(min-width: 1480px) {
  html {
    font-size: 12px;
  }
}

@media(min-width: 1680px) {
  html {
    font-size: 14px;
  }
}

@font-face {
  src: url('./fonts/nasalization-rg.otf');
  font-family: Nasalization
}

body {
  font-family: Nasalization;
  overflow-x: hidden;
  background-color: black;
  color: white;
  position: relative;
}

body::-webkit-scrollbar {
  opacity: 1;
  width: .5rem;
}

body::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 2rem;
}

body::-webkit-scrollbar-corner{
  opacity: 1;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes no-scroll {
  0% {
    overflow-y: hidden;
  }
  95% {
    overflow-y: hidden;
  }
  100% {
    overflow-y: auto;
  }
}
