.botao-de-teste {
  display: block;
  text-align: center;
  margin-top: -5rem;
  padding-bottom: 4rem;
}

.botao-de-teste__texto {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
}

.botao-de-teste__conteudo {
  position: relative;
  display: inline-block;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
  padding: 2rem 7.5rem;
  border-radius: 1rem;
  margin: 0 auto;
  margin-top: 4rem;
  color: white;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
}

.dispositivo-ios__conteudo {
  background-color: darkorange;
}

.botao-de-teste__background {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
        135deg,
        rgba(255, 0, 0, 1) 0%,
        rgba(255, 154, 0, 1) 10%,
        rgba(208, 222, 33, 1) 20%,
        rgba(79, 220, 74, 1) 30%,
        rgba(63, 218, 216, 1) 40%,
        rgba(47, 201, 226, 1) 50%,
        rgba(28, 127, 238, 1) 60%,
        rgba(95, 21, 242, 1) 70%,
        rgba(186, 12, 248, 1) 80%,
        rgba(251, 7, 217, 1) 90%,
        rgba(255, 0, 0, 1) 100%
        );
  width: 250rem;
  height: 8rem;
  z-index: 1;
  animation-name: animacao-background;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

.dispositivo-ios__background {
  display: none;
}

@keyframes animacao-background {
  0% {
    transform: translate(-50%, -50%);
  }
  25% {
    transform: translate(-10%, -50%);
  }
  75% {
    transform: translate(-90%, -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@media(max-width: 980px) {
  .botao-de-teste {
    margin-top: 0rem;
  }
}

@media(max-width: 900px) {
  .botao-de-teste__conteudo {
    font-size: 2.5rem;
  }

  .botao-de-teste__background {
    width: 280rem;
  }
}

@media(max-width: 700px) {
  .botao-de-teste__conteudo {
    margin-top: 7rem;
  }
}

@media(max-width: 600px) {
  .botao-de-teste__conteudo {
    font-size: 3rem;
    padding: 2rem 5rem;
  }

  .botao-de-teste__background {
    width: 320rem;
  }
}

@media(max-width: 340px) {
  .botao-de-teste__conteudo {
    font-size: 3rem;
    padding: 2rem 2rem;
  }
}
